.techs {
  background: var(--color-subpage);
}

.techs__container {
  padding: 100px 70px;
}

.techs__title {
  max-width: 600px;
  margin: 93px auto 30px;
  font-size: 47px;
  line-height: 1.16;
  text-align: center;
}

.techs__subtitle {
  max-width: 460px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 1.428;
  text-align: center;
}

.techs__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  grid-auto-flow: dense;
  gap: 10px;
  max-width: 690px;
  margin: 100px auto 0;
  padding: 0;
  list-style: none;
}


.techs__item {
  padding: 21px 10px;
  box-sizing: border-box;
  background: var(--color-set-three);
  text-align: center;
  border-radius: 10px;
}

/*! mod: item two column */
.techs__item_two-column {
  grid-column: auto / span 2;
}

@media(max-width: 769px) {
  .techs__container {
    padding: 90px 50px;
  }

  .techs__title {
    margin: 83px auto 22px;
  }

  .techs__subtitle {
    font-size: 12px;
    line-height: 1.5;
  }

  .techs__list {
    grid-template-columns: repeat(auto-fit, minmax(84px, 1fr));
    margin: 83px auto 0;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.25;
  }
}

@media(max-width: 426px) {
  .techs {
    background: var(--color-page);
  }

  .techs__container {
    padding: 70px 18px;
  }

  .techs__title {
    margin: 60px auto 28px;
    font-size: 28px;
    line-height: 1.2;
  }

  .techs__subtitle {
    font-size: 10px;
    line-height: 1.45;
  }

  .techs__list {
    grid-template-columns: repeat(2, 84px);
    justify-content: center;
    margin: 52px auto 0;
    padding: 0;
  }
}
