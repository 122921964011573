.portfolio__title {
  margin: 0 0 51px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.111;
  color: #8B8B8B;
}

.portfolio__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 30px;
  line-height: 1.666;
}

.portfolio__item:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.portfolio__link {
  display: block;
  width: 100%;
  padding: 0 30px 19px 0;
  box-sizing: border-box;
  position: relative;
}

.portfolio__link .arrow-svg {
  position: absolute;
  top: 20px;
  right: 3px;
}

@media(max-width: 769px) {
  .portfolio__list {
    font-size: 28px;
    line-height: 1.785;
  }
}

@media(max-width: 650px) {
  .portfolio__title {
    margin: 0 0 43px;
    font-size: 14px;
    line-height: 1.428;
  }

  .portfolio__list {
    font-size: 17px;
    line-height: 1.555;
  }

  .portfolio__link {
    padding-right: 20px;
  }

  .portfolio__link .arrow-svg {
    top: 2px;
    right: 5px;
    width: 10px;
  }
}
