.promo {
  display: flex;
  min-height: 524px;
  background: #073042;
}

.promo__block {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 730px;
  min-height: 320px;
  margin: 82px auto 0;
  position: relative;
}

.promo__bg-img {
  display: block;
  width: 320px;
  height: 320px;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: calc(50% - 160px);
  left: 50%;
  transform: translate(-50%);
}

.promo__title {
  margin: 0;
  font-size: 47px;
  font-weight: 400;
  line-height: 1.16;
  text-align: center;
  color: #fff;
}

@media(max-width: 769px) {
  .promo {
    min-height: 784px;
  }

  .promo__container {
    padding: 0 30px;
  }

  .promo__block {
    margin-top: 224px;
  }
}

@media(max-width: 600px) {
  .promo {
    min-height: 650px;
  }

  .promo__block {
    margin-top: 150px;
  }

  .promo__title {
    font-size: 36px;
  }
}

@media(max-width: 426px) {
  .promo {
    min-height: 570px;
  }

  .promo__container {
    padding: 0 10px;
  }

  .promo__block {
    min-height: 248px;
    margin-top: 142px;
  }

  .promo__bg-img {
    width: 248px;
    height: 248px;
    top: calc(50% - 124px);
  }

  .promo__title {
    font-size: 29px;
    line-height: 1.258;
  }
}
