.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.preloader__container {
  width: 70px;
  height: 70px;
  position: relative;
}

.preloader__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: preloader 2s linear infinite;
}
.preloader__item:nth-child(2),
.preloader__item:nth-child(4) {
  animation-delay: -1s;
}
@keyframes preloader {
  0% {
    transform: rotate(0deg);
    filter: hue-rotate(0deg);
  } 100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

.preloader__item i {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15%;
  height: 15%;
  background: var(--color-set-one);
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0 0 10px var(--color-set-one),
    0 0 20px var(--color-set-one),
    0 0 30px var(--color-set-one),
    0 0 40px var(--color-set-one),
    0 0 50px var(--color-set-one),
    0 0 60px var(--color-set-one);
}
