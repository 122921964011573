.nav {
  flex-grow: 1;
  display: flex;
}

.nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 350px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.nav__item {
  font-size: 13px;
  line-height: 1.384;
}

.nav__item_invisible {
  display: none;
}

.nav__link {
  color: inherit;
  text-decoration: none;
}

.nav__profile {
  display: flex;
  gap: 13px;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 0 0 10px 0;
  box-sizing: border-box;
}

@media(max-width: 769px) {
  .nav {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 99;
    width: 68%;
    min-height: 100%;
    padding: 16vh 30px 9vh;
    box-sizing: border-box;
    background: var(--color-page);
    transition: .1s;
    max-height: 100vh;
    overflow: auto;
  }

  .nav_open {
    right: 0;
  }

  .nav__list {
    flex-direction: column;
    gap: 28px;
  }

  .nav__item {
    font-size: 18px;
    line-height: 1.222;
  }

  .nav__item_invisible {
    display: block;
  }

  .nav__link {
    color: var(--color-text);
  }

  .nav__profile {
    margin-top: 50px;
  }
}


@media(max-width: 426px) {
  .nav_open {
    width: 100%;
  }

  .nav__list {
    gap: 24px;
  }
}
