.input-pass {
  width: 100%;
  position: relative;
}

.input-pass__show-pass {
  position: absolute;
  top: 15px;
  right: 3px;
  width: 24px;
  height: 28px;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
}

.input-pass__title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  line-height: 1.2;
  color: #8B8B8B;
}

.input-pass__input {
  width: 100%;
  margin-bottom: 6px;
  padding: 17px 30px 0 0;
  box-sizing: border-box;
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-text);
}

.input-pass__input:focus {
  border-color: var(--color-set-two);
}

.input-pass__input_border_bottom {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
}

.input-pass__input_type_error,
.input-pass__input_type_error.input-pass__input:focus {
  color: var(--color-error);
  border-color: var(--color-error);
}

.input-pass__error {
  margin: 0;
  min-height: 12px;
  color: var(--color-error);
  font-size: 10px;
  line-height: 1.2;
}

.input-pass__input:-webkit-autofill,
.input-pass__input:-webkit-autofill:hover,
.input-pass__input:-webkit-autofill:focus,
.input-pass__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-page) inset;
  -webkit-text-fill-color: var(--color-text);
  caret-color: var(--color-text);
}
