@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('./Inter-Medium.woff') format("woff");
}
