.theme {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 204px;
  margin: 0 auto 0 30px;
  color: var(--color-text);
}

.theme__toggle {
  display: flex;
  gap: 2px;
  padding: 2px;
  box-sizing: border-box;
  background-color: var(--color-search-switch-bg);
  border-radius: 15px;
}

.theme__item {
  position: relative;
}

.theme__control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  background: 0 0;
  border: none;
  outline: none;
  border-radius: 10px;
  appearance: none;
  cursor: pointer;
}

.theme__control:focus-visible {
  box-shadow: 0 0 5px var(--color-set-two);
}

.theme__text {
  display: block;
  padding: 3px 9px;
  opacity: .7;
  transition: opacity .2s;
  border-radius: 10px;
}

.theme__control:hover ~ .theme__text {
  opacity: 1;
}

.theme__control:checked ~ .theme__text {
  background: var(--color-page);
  opacity: 1;
}

@media(max-width: 650px) {
  .theme {
    order: 1;
    margin: 0 auto 20px;
  }
}
