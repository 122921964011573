.my-card {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  row-gap: 40px;
  margin: 66px 0 100px;
  position: relative;
}

.my-card__title {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0;
}

.my-card__data {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.my-card__meta {

}

.my-card__name {
  margin: 0;
  font-size: 50px;
  font-weight: 400;
  line-height: 1.16;
}

.my-card__subtitle {
  margin: 18px 0 26px;
  font-size: 18px;
  line-height: 1.111;
}

.my-card__description {
  margin: 0;
  line-height: 1.571;
}

.my-card__contacts {
  margin-top: auto;
  font-style: normal;
  font-weight: 500;
}

.my-card__link:not(:last-child) {
  margin-right: 20px;
}

.my-card__photo {
  display: block;
  width: 270px;
  height: 327px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

@media(max-width: 769px) {
  .my-card {
    margin: 66px 0 90px;
  }

  .my-card__name {
    font-size: 40px;
    line-height: 1;
  }

  .my-card__subtitle {
    font-size: 12px;
    line-height: 1.5;
    margin: 16px 0 20px;
  }

  .my-card__description {
    font-size: 12px;
    line-height: 1.5;
  }

  .my-card__photo {
    width: 255px;
    height: 307px;
  }
}

@media(max-width: 650px) {
  .my-card {
    flex-direction: column;
    margin: 60px 0 70px;
  }

  .my-card__data {
    order: 2;
  }

  .my-card__name {
    font-size: 30px;
    line-height: 1.2;
  }

  .my-card__subtitle {
    margin: 20px 0;
    font-size: 11px;
    line-height: 1.454;
  }

  .my-card__description {
    font-size: 11px;
    line-height: 1.454;
  }

  .my-card__photo {
    order: 1;
    width: 292px;
    height: 352px;
    margin: 0 auto;
  }

  .my-card__contacts {
    margin-top: 40px;
  }
}
