.overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
}

@media(max-width: 769px) {
  .overlay_visible {
    display: block;
  }
}
