.movie {
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  border-radius: 6px;
  box-shadow: 0px 4px 30px var(--color-movie-shadow);
  background: var(--color-movie-bg);
  cursor: pointer;
  transition: .3s;
}

.movie:hover,
.movie:focus-visible {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

.movie:focus-visible .button-card-delete,
.movie:hover .button-card-delete {
  opacity: 1;
}

.movie__cover {
  padding: 0 0 56% 0;
  box-sizing: border-box;
  position: relative;
}

.movie__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px 6px 0 0;
}

.movie__footer {
  padding: 16px;
  box-sizing: border-box;
}

.movie__data {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.movie__name {
  width: 88%;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.230;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.movie__meta {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 17px 0 0;
  font-size: 11px;
  line-height: 1.181;
  color: #8B8B8B;
}

@media(max-width: 1280px) {
  .movie__footer {
    padding: 14px;
  }

  .movie__meta {
    margin-top: 15px;
  }
}
