.title {
  width: 100%;
  margin: 0;
  padding-bottom: 23px;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.227;
  border-bottom: 1px solid var(--color-line-title);
}

@media(max-width: 426px) {
  .title {
    font-size: 18px;
    padding-bottom: 28px;
  }
}
