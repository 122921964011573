.footer {
  margin: auto 0 0;
  font-size: 13px;
  line-height: 1.230;
}

.footer_disable {
  display: none;
}

.footer__container {
  padding: 86px 70px 0;
}

.footer__title {
  margin: 0;
  text-align: center;
  color: #888;
}

.footer__bottom {
  margin: 20px 0 0;
  padding: 20px 0;
  border-top: 1px solid #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__cop {
  margin: 0;
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 0;
  max-width: 60%;
  list-style: none;
}

@media(max-width: 769px) {
  .footer__container {
    padding: 62px 30px 0;
  }
}

@media(max-width: 650px) {
  .footer {
    font-size: 12px;
  }

  .footer__container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer__bottom {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 12px;
  }

  .footer__cop {
    margin-top: 30px;
    order: 3;
  }

  .footer__list {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    order: 2;
  }
}
