.burger {
  display: none;
}

@media(max-width: 769px) {
  .burger {
    display: block;
    width: 28px;
    height: 23px;
    margin-right: 10px;
    position: relative;
    z-index: 100;
    cursor: pointer;
  }

  .burger_active {
    position: fixed;
    top: 28px;
    right: 27px;
    width: 20px;
    height: 16px;
    margin: 0;
  }

  .burger_theme_main .burger__item {
    background-color: #fff;
  }

  .burger__item {
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--color-text);
  }

  .burger__item:nth-child(2) {
    top: 0;
    left: 0;
    transform: translateY(-50%);
  }

  .burger__item:nth-child(2) {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .burger__item:nth-child(3) {
    left: 0;
    bottom: 0;
  }

  .burger_active .burger__item:nth-child(1) {
    display: none;
  }

  .burger_active .burger__item:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
  }

  .burger_active .burger__item:nth-child(3) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%) rotate(-45deg);
  }
}


@media(max-width: 426px) {
  .burger {
    width: 22px;
    height: 20px;
  }
}
