.short-films__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}

.short-films__switch {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  appearance: none;
  border: none;
  outline: none;
}

.short-films__switch-costum {
  display: block;
  width: 36px;
  height: 20px;
  border: none;
  border-radius: 20px;
  padding: 0;
  background: var(--color-search-switch-bg);
  position: relative;
  cursor: pointer;
}

.short-films__switch:focus-visible ~ .short-films__switch-costum {
  box-shadow: 0 0 5px var(--color-set-two);
  outline: 1px solid var(--color-set-two);
}

.short-films__switch-costum::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: var(--color-search-switch-item);
  transition: left .4s;
}

.short-films__switch:checked ~ .short-films__switch-costum {
  background: #2BE080;
}
.short-films__switch:checked ~ .short-films__switch-costum::before {
  background: #fff;
  left: 18px;
}

.short-films__text {
  font-size: 12px;
  line-height: 1.5;
}
